import React from "react";
import HeaderSlideshowStyles from "./Header-Slideshow.module.css";
import SlideshowCrossfade from "services/Slideshow-crossfade/Slideshow-crossfade";
import bioImage from "../../../assets/mobile/header/biologic-coordinator-icon.jpg";
import teachImage from "../../../assets/mobile/header/office-training-icon.jpg";
import servicesImage from "../../../assets/mobile/header/support-resources-icon.jpg";
import HeaderStyles from "../HeaderNew.module.css";
import { Image } from "react-bootstrap";

const HeaderSlideshow = () => {
  const slideshow = HeaderSlideshowStyles.slideshow;

  const mainTitle = HeaderStyles.mainTitle;
  const subTitle = HeaderStyles.subTitle;
  const contentParagraph = HeaderStyles.contentParagraph;
  const headerImg = HeaderStyles.headerImg;

  return (
    <SlideshowCrossfade className={slideshow}>
      <div>
        <p className={mainTitle}>YOUR ONE STOP SHOP FOR:</p>
        <p className={subTitle}>Biologic Coordinator Services </p>
        <Image
          className={headerImg}
          src={bioImage}
          alt={"biologic-coordinator-icon"}
        />
        <p className={contentParagraph}>
          Managing prior authorizations, appeals, and patient communication to
          ensure seamless access to medications.
        </p>
      </div>
      <div>
        <p className={mainTitle}>YOUR ONE STOP SHOP FOR:</p>
        <p className={subTitle}>Office Education </p>
        <Image
          className={headerImg}
          src={teachImage}
          alt={"office-training-icon"}
        />
        <p className={contentParagraph}>
          Customized in-office training to improve medication management,
          enhance staff proficiency, and streamline patient care
        </p>
      </div>
      <div>
        <p className={mainTitle}>YOUR ONE STOP SHOP FOR:</p>
        <p className={subTitle}>Support and Resources </p>
        <Image
          className={headerImg}
          src={servicesImage}
          alt={"support-resources-icon"}
        />
        <p className={contentParagraph}>
          Access to up-to-date information, tools, and templates to assist with
          documentation and staying informed on industry developments.
        </p>
      </div>
    </SlideshowCrossfade>
  );
};

export default HeaderSlideshow;
