import HeaderStyles from "../Header/Header.module.css";

const quoteIcon = HeaderStyles.quoteIcon;

const QuoteOne = () => {
  return (
    <>
      <svg
        className={quoteIcon}
        viewBox="0 0 26 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.69016 0.759999C9.08216 0.759999 10.1862 1.264 11.0022 2.272C11.8182 3.28 12.2262 4.648 12.2262 6.376C12.2262 8.104 11.8662 9.784 11.1462 11.416C10.4742 13 9.63416 14.296 8.62616 15.304C7.66616 16.312 6.63416 17.176 5.53016 17.896C3.51416 19.288 2.09816 19.984 1.28216 19.984C1.09016 19.984 0.850156 19.72 0.562156 19.192C0.274156 18.616 0.130156 18.136 0.130156 17.752C3.53816 16.168 5.36216 13.744 5.60216 10.48L2.50616 6.016C2.50616 4.384 3.01016 3.112 4.01816 2.2C5.07416 1.24 6.29816 0.759999 7.69016 0.759999ZM21.2262 0.759999C22.6182 0.759999 23.7222 1.264 24.5382 2.272C25.3542 3.28 25.7622 4.648 25.7622 6.376C25.7622 8.104 25.4022 9.784 24.6822 11.416C24.0102 13 23.1702 14.296 22.1622 15.304C21.2022 16.312 20.1702 17.176 19.0662 17.896C17.0502 19.288 15.6342 19.984 14.8182 19.984C14.6262 19.984 14.3862 19.72 14.0982 19.192C13.8102 18.616 13.6662 18.136 13.6662 17.752C17.0742 16.168 18.8982 13.744 19.1382 10.48L16.0422 6.016C16.0422 4.384 16.5462 3.112 17.5542 2.2C18.6102 1.24 19.8342 0.759999 21.2262 0.759999Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default QuoteOne;
