import React from "react";
import HeaderStyles from "./HeaderNew.module.css";
import { Button, Image } from "react-bootstrap";
import LikedIn from "../../assets/mobile/header/linkedIn.png";

import useMediaQuery from "services/useMediaQuery";
import HeaderSlideshow from "../Header/HeaderSlideshow/Header-Slideshow";
import textLogo from "../../assets/logo-text.png";
import QuoteOne from "Components/Quote/Quote";
import bioImage from "../../assets/mobile/header/biologic-coordinator-icon.jpg";
import teachImage from "../../assets/mobile/header/office-training-icon.jpg";
import servicesImage from "../../assets/mobile/header/support-resources-icon.jpg";

const HeaderDesktop = () => {
  const matchedDesktop = useMediaQuery("(min-width: 728px)");

  const mainContainer = HeaderStyles.mainContainer;
  const logo = HeaderStyles.logo;
  const titleContainerDesktop = HeaderStyles.titleContainerDesktop;
  const titleDivider = HeaderStyles.titleDivider;
  const slideshowContainer = HeaderStyles.slideshowContainer;
  const socialContainer = HeaderStyles.socialContainer;
  const link = HeaderStyles.link;
  const contentContainer = HeaderStyles.contentContainer;
  const introContainerDesktop = HeaderStyles.introContainerDesktop;
  const scheduleButton = HeaderStyles.scheduleButton;
  const reviewsContainer = HeaderStyles.reviewsContainer;
  const slogan = HeaderStyles.slogan;
  const desktopBgImage = HeaderStyles.desktopBgImage;
  const divider = HeaderStyles.divider;
  const logoImg = HeaderStyles.logoImg;
  const logoDiv = HeaderStyles.logoDiv;
  const mainTitle = HeaderStyles.mainTitle;
  const subTitle = HeaderStyles.subTitle;
  const contentParagraph = HeaderStyles.contentParagraph;
  const headerImg = HeaderStyles.headerImg;
  const contentDiv = HeaderStyles.contentDiv;
  const contentDivSelector = HeaderStyles.contentDivSelector;

  const url =
    "https://www.linkedin.com/posts/brandis-boyd-cooper-cma-cdt-b534431a5_access-activity-7115025415395545088-CpSG?utm_source=share&utm_medium=member_android&fbclid=IwAR0kEDt7lFxUzC2evdHFfIzwJjUubZDA_oANCDtKFaKEL6FCVAKWEqMpTi4";

  return (
    <>
      {matchedDesktop && (
        <main className={mainContainer}>
          <span className={desktopBgImage}></span>
          <span className={socialContainer}>
            <a href={url}>
              <Image src={LikedIn} className={link} />
            </a>{" "}
          </span>
          <section className={slideshowContainer}>
            {/* <HeaderSlideshow /> */}
          </section>

          <section>
            <div className={logo}></div>

            <div className={introContainerDesktop}>
              <div>
                <h3>Welcome to</h3>
                <div className={logoDiv}>
                  <Image className={logoImg} src={textLogo} alt="logo" />
                </div>
              </div>
              <span className={titleDivider} />
              <div
                style={{
                  padding: ".5rem 1rem 1rem 1rem",
                }}
              >
                <p className={slogan}>DESIGNED WITH SPECIALTY MEDS IN MIND!</p>
                <div className={contentDiv}>
                  <div>
                    <p className={mainTitle}>Biologic Coordinator Services</p>
                    <Image
                      className={headerImg}
                      src={bioImage}
                      alt={"biologic-coordinator-icon"}
                    />
                    <p className={contentParagraph}>
                      Managing prior authorizations, appeals, and patient
                      communication to ensure seamless access to medications.
                    </p>
                  </div>
                  <div>
                    <p className={mainTitle}>Office Education</p>
                    <Image
                      className={headerImg}
                      src={teachImage}
                      alt={"office-training-icon"}
                    />
                    <p className={contentParagraph}>
                      Customized in-office training to improve medication
                      management, enhance staff proficiency, and streamline
                      patient care
                    </p>
                  </div>
                  <div>
                    <p className={mainTitle}>Support and Resources </p>
                    <Image
                      className={headerImg}
                      src={servicesImage}
                      alt={"support-resources-icon"}
                    />
                    <p className={contentParagraph}>
                      Access to up-to-date information, tools, and templates to
                      assist with documentation and staying informed on industry
                      developments.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* might be a moving slideshow? */}
            <div className={reviewsContainer}>
              <h4>
                <div
                  style={{
                    display: "inline-block",
                    position: "relative",
                    top: "-8px",
                  }}
                >
                  <QuoteOne />
                </div>
                <b>Brandis is the best biologic coordinator in the game!</b>
                <div
                  style={{
                    display: "inline-block",
                    rotate: "180deg",
                    position: "relative",
                    top: "3px",
                  }}
                >
                  <QuoteOne />
                </div>
              </h4>

              <p style={{ textAlign: "left", margin: "0 0 0 .8rem" }}>
                -- Dr.Sklar
              </p>
            </div>
            <div style={{ textAlign: "center" }}>
              <Button href="/#contact" className={scheduleButton}>
                BOOK A CONSULTATION TODAY
              </Button>
            </div>
          </section>
          <section className={divider}></section>
        </main>
      )}
    </>
  );
};

export default HeaderDesktop;
